.company_logo
{
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding:0 10px;
    width: 100%;
    height: auto;
}
/* .company_logo.side
{
padding-top: 15px;
} */
.company_logo img.logo,
.drawerheader .company_logo img.logo
{
    width:50px !important;
    height: auto !important;
}
 img.logo_close
{   
    margin-right: auto;
    background-color: white;
    border-radius: 10px;
    width: 40px !important;
    height: 40px !important;
}
/* .company_logo.side img.logo
{
    width:45px !important;
    height: auto !important;
} */
.company_logo .company_name
{
    
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    white-space: break-spaces;
    word-break: break-word;
    animation: reveal 0.2s ease;
}
@keyframes reveal {
    0%{
        display: none;
    }
    99%{
        display: none;
    }
    100%{
        display: flex;
    }
}
/* .company_logo.side .company_name
{
    margin-left: 15px;
} */
.drawerMain span
{
    line-height: 2 !important;
}
.sidebar-list-items
{
    cursor: pointer !important;
}