.swal2-popup.swal2-toast
{
padding: 10px !important;
}

.swal2-popup
{
/* max-width: 85%; */
overflow-x: hidden;
}

body.swal2-toast-shown .swal2-container.swal2-top-end, 
body.swal2-toast-shown .swal2-container.swal2-top-right
{
    z-index: 9999 !important;
}