.edit-lab-btn {
    width: 20px !important;
}
.lab-provision-btn-section
{   

    gap: 10px;
    /* width: 100%; */
    display: flex !important;  
    align-items: center ;
}
/* .edit-lab-btn
{
    width: 50%;
} */
.lab-provision-btn
{   
    margin-top: auto !important;
    /* margin-top: 0px !important; */
}
.manage-lab-btn {
    width: 100% !important;
}
/* .view-main-card
{   
    padding: 0px 10px !important;
} */
.card_content
{
    padding: 15px !important;
}
.instance-details-labels {
    font-weight: 600 !important;
}
.labsteps-tab .labsteps-tab-btn:active
{
    background-color: #E40017 !important;
}
.labsteps-tab .labsteps-tab-btn
{
    background-color: #808080 ;
    min-height: 10px !important;
    border-radius: 20px !important;
}
.lab-provision-btn .MuiButton-root
{
    margin: 0px !important;
    background-color: #808080 !important;
    max-height:35px !important;
    padding: 6px 20px !important;
}
.labsteps-tab .MuiTabs-flexContainer
{
    overflow-x: auto !important;
    padding-bottom: 5px !important;
}
.labsteps-tab .MuiTabs-flexContainer::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
	background-color: #F5F5F5 !important;
    border-radius: 25px !important;
}

.labsteps-tab .MuiTabs-flexContainer::-webkit-scrollbar
{
    height: 4px;
	background-color: #F5F5F5 !important;
}

.labsteps-tab .MuiTabs-flexContainer::-webkit-scrollbar-thumb
{
	background-color: #E40017 !important;
    border-radius: 25px !important;
}
.lab-provision-btn
{
    text-align: end;
    display: flex;
    justify-content: flex-end;
}
body
{
    padding: 0 !important;
}
.onboarding-modal
{
    border-radius: 5px !important;
    border: none !important;
    width: 400px;
}
.onboarding-modal .input-text
{
    width: 100% !important;
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.42) !important;
    padding: 5px;
    border-radius: 5px;
}
.onboarding-modal:hover .input-text
{
    border: 1px solid rgba(0, 0, 0, 0.42) !important;
    border-radius: 5px;
}
.onboarding-modal .input-text::before,
.onboarding-modal .input-text:after
{
 border-bottom: 0px !important;
 transition: unset !important;
}
.error-message
{
    color: var(--active) !important;
}
.cancel-button
{
    background:  red !important;
    color: #fff !important;
    margin-left: 10px !important;
}
#parent-modal-title
{
    font-weight: 600;
}
.step-description
{
    overflow: hidden;
}
.small-text-gray.instance-tag
{
    font-weight: 800 !important;
    color: black !important;
    margin-right: 30px;
}
.instance-grid-main
{
    width: 100%;
    align-items: center;
}
.instance-details-main
{
    /* margin-top: 25px; */
    /* background-color:#E9ECEF; */
    padding:15px;
    border-radius: 3px;
    /* border: 1px solid #cfcfcf; */
}
.instance-data-margin-bottom
{
    margin-bottom: 15px;
}
.instance-details-input
{
    padding:12px 10px;
    border:1px solid #cfcfcf !important;
    width: 100%;
    border-radius: 3px;
    background-color: #E9ECEF;
}
.instance-dark-btn
{
    min-height: 40px;
    height: 100%;
    /* background: #71C284 !important; */
    box-shadow: none;
}
.instance-details-input:focus-visible
{
    outline: none;
}
/* .instance_button_main .instance-dark-btn{
    margin-right: 10px;
} */
.credential-link-open
{
    border-radius: 5px 0px 0px 5px !important;
    background-color: var(--black) !important;
}
.copy-instance-text-btn
{   
    
    border-radius: 0px 5px 5px 0px !important;
    background-color: var(--active) !important;
}
.dark-shade-instance
{
    background: #F3F1F3 !important;
}
.light-shade-instance
{
    background: #FFFEFF !important;
}
.tabs-1
{
    margin-top: 50px;
}
.tabs {
    width: 400px;
    display: inline-block;
    margin-right: 30px;
    vertical-align: top;
}

.tabs-1 .tab-links {
    /* margin: 0;
    padding: 0;
    border: 1px solid transparent;
    border: 1px solid ;
    border-bottom: 1px solid #86868600;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 35px;
    height: 47px;
    position: relative;
    top: 6px; */
   
    margin: 0;
    padding: 20px 10px !important;
    gap: 20px;
    border: 1px solid transparent;
    border-bottom: 1px solid #86868600;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: fit-content;
    position: relative;
    display: flex;
    overflow: auto hidden;
    min-width: 100%;
}
/* .tab-links{
      overflow: scroll;
    display: flex;
    justify-content: flex-start;
    border: 1px solid;
    overflow-y: hidden
} */

.tabs-1 .tab-link {
    padding: 0px 10px;
    cursor: pointer;
    border: 1px solid #868686;
    border-radius: 100px;   
    display: inline-block;
    height: 36px;
    font-weight: 400;
    font-size: 18px;
    background-color: var(--black)!important;
    color: #ffffff;
    min-width: 100px;
}

.tabs-1 .tab-link-active {
    font-weight: bold;
    border: 1px solid transparent;
    border-bottom: 1px solid transparent;
 
    background-color: var(--active) !important;
    box-shadow: 0px 0px 10px 0px rgb(170 167 167 / 72%) !important;
    font-weight: 400;
    font-size: 18px;
    color: white;
}
.tabs-1 .content {
    padding: 15px;
    box-shadow: 0px 0px 10px 0px rgb(170 167 167 / 72%) !important;
    border: 1px solid transparent;
    border-top: none;
}
@media only screen and (max-width: 991.5px)
{
    .onboarding-modal.MuiBox-root.css-105qr5e {
        width: 400px !important;
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px){
    button.tab-link
    {
        width: 50% !important;
    }
 
    /* .tabs-1 .tab-links
    {
        height: auto !important;
    } */
    .lab-provision-btn .MuiButton-root {
        padding: 6px 15px !important;
    }
    .lab-provision-btn-section
    {
    display: block !important;
    align-items: center ;
    }
    .edit-lab-btn
    {
        width: 100%;
        margin: 10px 0 0 8px !important;
    }
    .lab-provision-btn-section
    {
        width: 100% !important;
        text-align: center;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px){

    button.tab-link
    {
        width: 25% !important;
    }
    /* .tabs-1 .tab-links
    {
        height: auto !important;
    } */
}
@media only screen and (max-width: 480px)
{
    .onboarding-modal.MuiBox-root.css-105qr5e {
        width: 300px !important;
    }
    
}
.view-page-content * {
    line-height: 1.5;
}
.view-page-content ul,.view-page-content ol
{
    padding: 0 25px !important;
}
.view-page-content .table{
    /* border: 1px double #b3b3b3 !important; */
    border-collapse: collapse !important;
    border-spacing: 0 !important;
    height: 100% !important;
    /* width: 100% !important; */
  }
.view-page-content .table th {
    background: rgba(0,0,0,.05);
    font-weight: 700;
  }
.view-page-content .table td,.table th {
    border: 1px solid #bfbfbf;
    min-width: 2em;
    padding: 0.4em;
}
.view-page-content p{
    color: #000000;
    font-weight: 400;
    line-height: 1.2;
}
.view-page-content a{
    text-decoration: underline;
}
.image-style-align-left {
    float: left;
    /* margin-right: var(--ck-image-style-spacing); */
}
.view-page-content .image-style-block-align-left
{
    margin-left: 0 !important;
}
.view-page-content .image-style-align-left {
    float: left !important;
    margin-right: 1.5em !important;
}
.view-page-content .image-style-block-align-right
{
    /* margin-left: auto !important; */
    margin-right: 0 !important;
}
.view-page-content .image-style-align-right {
    float: right !important;
    margin-left: 1.5em !important;
}
.view-page-content .image
{
    margin: 0 auto;
    text-align: center;
}
.view-page-content .ck-editor ul,.view-page-content .ck-editor ol
{
    list-style-type: auto !important;
}
.step-description ul li {
    list-style: unset !important;
}
/* .view-page-content .ck.ck-editor__editable_inline>:first-child {
    margin-top: var(--ck-spacing-large);
} */
.view-page-content blockquote {
    border-left: 5px solid #ccc;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;
}
.view-page-content .ck.ck-editor__editable .image, .ck.ck-editor__editable .image-inline {
    position: relative;
}
.view-page-content .ck-content .image.image_resized {
    box-sizing: border-box;
    display: block;
    max-width: 100%;
}
.view-page-content .ck-content .image-style-align-left {
    float: left;
    /* margin-right: var(--ck-image-style-spacing); */
}
.view-page-content .ck-content .image-style-align-left, .ck-content .image-style-align-right {
    clear: none;
}
.view-page-content img{
    width: 90% !important;
}
.user-name-date-icon-wrapper 
{
    display: flex;
}
.user-mane-icon-main
{
    margin-right: 5px;
}
.user-name-bold 
{
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}

.user-date
{
    font-weight: 300;
    font-size: 12px !important;
}
.date-rating-icon-main
{
    text-align: end;
}
.comment-text
{
    margin-bottom: 20px;
}

.return_refund{
    font-size: 10px !important;
}


/* Changes in Card UI */
.viewLabCard{
    display: grid;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: auto;

}
.viewLabCard_image_container{
    display: flex;
    justify-content: center;
    height: auto;
    width: 100%;
    /* background-color: rebeccapurple; */
    padding: 0% !important;
}
.viewLabCard_image{
    height: 200px;
    width: 200px;
    object-fit: contain;
    border-radius: 10px;
    transition: all 0.5s ease;
    border-radius: 10px;

}

.labsteps-tab{
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10px !important;
    flex-direction: column;
    transition: all 0.5s ease;

}
.viewLabCard_icon{
    height: 25px;
    width: 25px;
    object-fit: cover;
}



.instance_button_main{
    display: flex;
    height: auto;
    
}

@media only screen and (min-width:1200px){
    .instance_button_main{
        flex-direction: column;
        
    }
    .credential-link-open
    {
        width: 100%;
    border-radius: 5px 5px 0px 0px !important;

    }
    .copy-instance-text-btn
    {   
    
    border-radius: 0px 0px 5px 5px !important;
  
    }
}
