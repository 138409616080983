:root {
	--black: #000;
	--white: #ffffff;
	--hilight-text: #797979;
	/* --active: #ff485a; */
	--search-backgroud: #eeeeee;
	--sidebar-active-bg: #0d0d0d;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	/* font-family: "Montserrat-Regular"; */
}

.ck.ck-editor__editable_inline
{
	padding: 0px 0px 0px 20px !important; 
}