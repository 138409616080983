@font-face {
  font-family:Montserrat-Regular;
  src: url(assets/font/montserrat/Montserrat-Regular.ttf);
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(assets/font/montserrat/Montserrat-Bold.ttf);
}
@font-face {
  font-family: Montserrat-ExtraBold;
  src: url(assets/font/montserrat/Montserrat-ExtraBold.ttf);
}
@font-face {
  font-family: Montserrat-Medium;
  src: url(assets/font/montserrat/Montserrat-Medium.ttf);
}
@font-face {
  font-family: Montserrat-Light;
  src: url(assets/font/montserrat/Montserrat-Light.ttf);
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url(assets/font/montserrat/Montserrat-SemiBold.ttf);
}
html,
body,
div,
/* span, */
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
/* strong, */
sub,
sup,
tt,
var,
/* b, */
u,
/* i, */
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  color: var(--main-black);
  font-weight: 400;
  /* font-family:""Roboto","Helvetica","Arial",sans-serif"; */
  font-family: Montserrat-Medium;
}
/* *,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  zoom: 1;
  font-family:"Montserrat-Regular";
} */

html {
  font-size: 16px;
  min-height: 100%;
  overflow-x: hidden;
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  font-family: Montserrat-Medium !important;
}

body {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  font-family: Montserrat-Medium !important;
  background-color: var(--main-white);
  color: var(--main-black);
  /* overflow-x: hidden; */
}

a,
ul,
li,
tr,
td
{
  font-size:14px !important;
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  font-family: Montserrat-Medium;
  text-decoration: none;
  color: inherit;
}
a,
a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
  /* font-family: Montserrat-light; */
}
  
  
/* 2) Heading */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  font-weight: normal;
  color: var(--main-black);
  font-weight: 800;
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  font-family: Montserrat-Medium;
}

h1 {
  font-size:36px;
  line-height:40px;
}

h2 {
  font-size:28px;
  line-height:32px;
}

h3 {
  font-size:24px !important;
  line-height: 1.2222em;
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  font-family: Montserrat-Medium;
}

h4 {
  font-size:22px;
  line-height: 1.25em;
}

h5 {
  font-size:20px;
  line-height: 1.1428em;
}

h6 {
  font-size:18px;
}

p {
  font-size:14px;
  line-height: 1.1428em;
  color: #454545;
  font-weight: 400;
  line-height: 25px;
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  font-family: Montserrat-Medium;
}

img {
  width: 100%;
  vertical-align: middle;
  border-style: none;
}

.MuiAutocomplete-popper  img {
  width: auto !important;
}

.btn:focus {
  box-shadow: none;
}

.transition {
  transition: all 0.3s ease-in-out;
}
.fa {
  font-family: "FontAwesome" !important;
}
:root {
--black: #000;
--white: #ffffff;
--highlight-text: #797979;
/* --active: #ff485a; */
--active:  #6AB43E !important;
/* --secondary-dark: #06532f; */
--secondary-dark: #388831 !important;
--secondary-light : #165153;
--input: #3888315e;

/* --input : #6ba518; */
--search-backgroud: #eeeeee;
--sidebar-active-bg: #0d0d0d;
/* --light-button:#ff485a;
 */
 --light-button: #6AB43E !important;
--gray-text:#828282;
}


/* title-css::Start */

/* title-css::End */




.admin-title
{
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300))) ;
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  color:var(--placeholder-color);
  font-weight:600;
}
.small-text
{
  font-size: calc(12px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  color:var(--gray-text);
  font-weight: 700;
}
.small-text-gray
{
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  font-family: Montserrat-Medium;
  color:var(--gray-text) !important;
  font-weight: 400;
}
.small-text-dark
{
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;  */
  font-family: Montserrat-Medium;
  color:var(--black);
  font-weight: 400;
}
.small-text-bold
{
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  font-family: Montserrat-Medium;
  color:var(--black);
  font-weight:700 !important;
}
.breadcrumb
{
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  font-family: Montserrat-Medium;
  color: var(--black) !important;
  font-weight:500 !important;
  text-decoration: none !important;
}
.subtitle
{
  font-size: calc(25px + (28 - 25) * ((100vw - 300px) / (1600 - 300)));
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  color: var(--black) !important;
  font-weight:700 !important;
  text-decoration: none !important;
}
.appbarTitle
{
  font-size: calc(25px + (28 - 25) * ((100vw - 300px) / (1600 - 300)));
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  color: var(--black) !important;
  font-weight:700 !important;
  text-decoration: none !important;
}
.card_title
{
  font-size: calc(20px + (25 - 20) * ((100vw - 300px) / (1600 - 300)));
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  font-family: Montserrat-Medium;
  color: var(--black) !important;
  font-weight:700 !important;
  text-decoration: none !important;
}
.active 
{
  color: var(--active) !important;
}
.card_info_para {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding: 15px;
  display: flex;
  justify-content: space-between;
}
    
.MuiTableCell-root
{
  border-bottom: none !important;
  padding:5px !important;
}


.MuiTableHead-root,
.MuiTableRow-root.MuiTableRow-hover,
.MuiTableRow-root.MuiTableRow-hover:hover {
  white-space: nowrap;
}
.MuiButton-label
{
  font-size: calc(12px + (12 - 10) * ((100vw - 300px) / (1600 - 300)));
  font-size: 20px !important;
  color: var(--black) !important;
}
.table_data p 
{
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
  /* color:var(--hilight-text) !important; */
}
.table_data a:hover{
  text-decoration: underline;
}
.appbarmain
{
  color: var(--black) !important;
  background-color: var(--white) !important;
  box-shadow: none !important;
  padding: 0px !important;
}
.drawerMain
{
   background-color: var(--black) !important;
}
@media only screen and (max-width : 991.5px) {
  .drawerMain
  {
    z-index: 1000 !important;
  }
  .appbarmain 
  {
    z-index: 999 !important;
  }
}
.datatable_main,
.table_main
{
  width: 100%;
}
 .MUIDataTableToolbar-actions-101 {
  text-align: end !important;
}
 /* .MuiCircularProgress-root
 {
   margin: 0 auto;
 } */
 @media only screen and (max-width : 991.5px) {
/* .MuiBox-root
{
  width: 100vw !important;
} */
.center-content
{
  height:100% !important;
}
}
@media only screen and (min-width : 1024px) and (max-width : 1366px) {
  .MuiBox-root
  {
    width: 100vw;
    overflow:auto;
  }
  .text-alignment
  {
      width: 100%;
  }
  }
.account_dropdown ul li{
  padding: 18px;
  justify-content: center;
  display: flex;
}
.account_dropdown
.css-112ed9h-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper:before {
  right: 25px !important;
}
.MuiTypography-body2 {
  color: inherit;
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  font-family: Montserrat-Medium;

  color: var(--gray-text) !important;
  font-weight: 400 !important;
}
.MuiTablePagination-select {
  text-align-last: right;
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  font-family: Montserrat-Medium;

  color: #000000;
  padding: 10px;
  border-radius: 5px;
  font-weight: 400;
}
.MuiSelect-selectMenu {
  
  background-color: var(--black) !important;
  color: white !important;
  padding:5px 15px!important;
  border-radius: 5px !important;
  min-height: 0px!important;
}
/* .MuiSelect-select:focus {
  border-radius: 0;
  background-color: var(--active) !important;
} */
.MuiTablePagination-selectIcon
{
color: white !important;
padding-left:7px;
}
.main_card
{
  border-radius: 10px !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: 0px 0px 10px 0px rgb(170 167 167 / 72%) !important;
  margin-top: 35px !important;
}

.feature-image-style {
  width: 350px !important;
  margin-top: 20px !important;
}
.policy-spacing
{
  padding: 0 16px !important;
  margin: 0 !important;
}
.policy-spacing li
{
  padding-top: 4px !important;
}
.second_subtitle {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: var(--light-button);
}
.account-menu-dropdown {
  display: flex !important;
  justify-content:center;
  background:#000 !important;
  color:white !important;
  margin:10px !important;
  padding:6px !important;
  border-radius: 50px !important;
}

/* new-css(20-09-2022)::start*/
.logo_main img
{
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline-block;
}
.center-content,.form-img-section
{
  display: flex;
  align-items: center;
  height: 100vh;
}

.button-white 
{
  background-color: var(--main-white) !important;
  color: var(--main-black) !important;
  font-size: 14px !important;
  /* font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; */
  font-family: Montserrat-Medium;

  border-radius: 5px !important;
}
.view-icon-input 
{
  top: 0;
  right:0;
}
/* new-css(20-09-2022)::end*/
.step-description ul,
.step-description ul li
{
  list-style: disc;
}
.step-description ol,
.step-description ol li
{
  list-style: auto;
}
.step-description a,
.ck-editor a
{
  text-decoration: underline;
}
.step-description
{
  overflow: unset !important;
  margin-left: 30px !important;
}

*
/* {
  list-style: unset;
} */


/*  */
.button-full-width
{
  width: 100% !important;
}
.text-ellips
{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* */
.main_header{
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 10px; */
}
.primary_text{
  padding: 10px 0;
  font-size: 23px;
}
.secondary_text{
  padding: 10px 0;
}
.list_main{
  padding: 0 50px !important;
  font-weight: unset;
}
.table_container, .table_container th, .table_container td{
  border: 1px solid black;
  border-collapse: collapse;
}
.detail-header .small-font{
  font-size: 12px !important;
}
/* TABLE UI CHANGES START HERE*/
th.MuiTableCell-head{
  background-color: #000!important;
  
}
.MuiTableCell-head .MuiButton-label > div >div {
  color: white !important;
}
.MuiTableCell-head .MuiButton-label .MuiButtonBase-root > svg{
  color: white !important;
}

.MuiTableRow-head>th>span{
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* background-color: #ff485a; */

}
.MuiTableRow-head>th>span>button{
  position: relative;
  left: 20px;
 
}

.MuiTableBody-root> .MuiTableRow-root:not(:last-child) 
{
  border-bottom:1px solid #B7B7B7 !important;
  padding: 1px !important;
}

.MuiPaper-rounded > div:has(> table:only-child){
  border: 1px solid  #000;
  border-radius: 10px 10px 0px 0px;
}
td{
  position: relative;
}
 .table_data{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
 }
 .table_data:not(.table_data_index) ::after{
    content: '';
    height: 50%;
    width: 2px;
    top: 25%;
    left: 0;
    position: absolute;
    background-color:  #B7B7B7 ;
 }
 .action_div{
  display: flex;
  justify-content: center;
  padding: 0px 10px;
 }
 .action_div::after{
  content: '';
  height: 50%;
  width: 2px;
  top: 25%;
  left: 0;
  position: absolute;
  background-color:  #B7B7B7 ;
}
tr.MuiTableRow-footer{
  border-bottom: 0 !important;
}
td.MuiTableCell-footer{
  background-color: #000 !important;
  border-radius: 0px 0px 10px 10px;
  
}
td.MuiTableCell-footer .MuiTablePagination-caption{
  color: white !important;
  
}
td.MuiTableCell-footer .MuiTablePagination-actions{
  color: white !important;
}
/* TABLE UI CHANGES END HERE*/
.muiMainTable .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters
{
    justify-content: end;
}
.muiMainTable .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters > div
{
    flex: 0 0 auto;
}

.muiMainTable .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters > div:nth-of-type(1) svg:nth-of-type(1)
{
    margin-top: 5px;
}
.muiMainTable .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters > div:nth-of-type(1) button.MuiButtonBase-root
{
    padding-top: 0;
    margin-right: -42px;

}
.muiMainTable  .MuiToolbar-gutters [class*="MUIDataTableToolbar-iconActive-"]
{
    display: none !important;
}

.view-page-content table
{
  border-collapse: collapse !important;
  width: inherit !important;
}
.company_logo.side
{
  top: 0;
    position: absolute;
    width: auto;
}
.signup_form_main
{
  padding-bottom: 20px;
}
body 
{
  font-family: "Montserrat-Regular";
}
h1,h2,h3,h4,h5,h6
{
  font-family: "Montserrat-SemiBold" !important;
}
p,span,table,tr,td,a,button{
  font-family: "Montserrat-Regular";
}

::placeholder 
{
  font-family: "Montserrat-Medium";
}
.input-main input,
.input-main label,
.input-main select 
{
  font-family: "Montserrat-Medium" !important;
  font-size: 12px !important;
}
.signup-text-line
{
  margin-top: 10px !important;
}
main.MuiBox-root
{
  width: 100%;
  overflow: hidden;
}
[class*=MUIDataTableFilterList]{
  padding: 10px 0px;
}